import { CommonModule } from '@angular/common';
import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-ref-data-table',
  standalone: true,
  imports: [TableModule, ButtonModule, TooltipModule, InputTextModule,
    DialogModule, InputSwitchModule, DropdownModule, FormsModule,
    ReactiveFormsModule, CommonModule,  CheckboxModule, ConfirmPopupModule],
  providers: [ConfirmationService],
  templateUrl: './ref-data-table.component.html',
  styleUrl: './ref-data-table.component.scss'
})
export class RefDataTableComponent {

  @Input() createNewRecordLabel: string;
  @Input() tableData: any[];
  @Input() isColumnResizable?: boolean = false;
  @Output() enableNewDialogModel = new EventEmitter();
  @Output() enableEditDialogModel = new EventEmitter<any>();
  @Output() activateDeactivate = new EventEmitter<any>();
  @Input() cols: any[]; 
  @Input() globalFilters: string[];
  @Input() loading: boolean = false;
  expandedRows: { [key: string]: boolean } = {};

  constructor(private confirmationService: ConfirmationService, public userService: UserService) {
    
  }

  enableNewDialog(){
    this.enableNewDialogModel.emit();
  }

  enableEditDialog(rowData: any) {
    this.enableEditDialogModel.emit(rowData);
  }

  activateDeactivateRefData(rowData: any) {
    console.log(rowData)
    this.activateDeactivate.emit(rowData);
  }

  getTruncatedText(text: string, rowId: string): string {
    if (this.expandedRows[rowId] || text.length <= 200) {
      return text;
    }
    return text.substring(0, 200) + '...';
  }

  ngOnInit() {
    //If globalFilters is not provided let's use the col.field from the cols
    //except if the col.inputType is a dropdown then we need to reference [col.field]?.[col.entityPropertyName]
    if(this.globalFilters == null) {
      this.globalFilters = this.cols.map(col => {
        if(col.inputType == 'dropdown') {
          return col.field + '.' + col.entityPropertyName;
        } else {
          return col.field;
        }
      });
    }

    //Remove the actionButtons from the globalFilters
    this.globalFilters = this.globalFilters.filter(filter => filter !== 'actionButtons');
  }

  getSortableField(col: any): string {
    // If the column has a nested field (like for dropdowns), use the entityPropertyName
    return col.inputType === 'dropdown' && col.entityPropertyName ? `${col.field}.${col.entityPropertyName}` : col.field;
  }
}
