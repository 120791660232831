import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];

    ngOnInit() {

        //For Production we are not ready to show all the menu items yet
        if (environment.name === 'prod') {
            this.model = [
                {
                    label: 'Accounts',
                    icon: 'pi pi-fw pi-users',
                    items: [
                        {
                            label: 'User Profiles',
                            icon: 'pi pi-fw pi-user',
                            routerLink: ['/accounts/user-profiles'],
                        }
                    ],
                },
                {
                    label: 'Ref Data',
                    icon: 'pi pi-home',
                    items: [
                        {
                            label: 'Security',
                            icon: 'pi pi-fw pi-shield',
                            items: [
                                {
                                    label: 'Users',
                                    icon: 'pi pi-fw pi-users',
                                    routerLink: ['/ref-data/security-users'],
                                },
                                {
                                    label: 'Roles',
                                    icon: 'pi pi-fw pi-shield',
                                    routerLink: ['/ref-data/security-roles'],
                                },
                            ]
                        },
                        {
                            label: 'Portfolio',
                            icon: 'pi pi-fw pi-sitemap',
                            routerLink: ['/ref-data/portfolio'],
                        },
                        {
                            label: 'Blotters',
                            icon: 'pi pi-fw pi-list',
                            items: [
                                {
                                    label: 'Blotter',
                                    icon: 'pi pi-fw pi-list',
                                    routerLink: ['/ref-data/blotter'],
                                },
                                {
                                    label: 'Blotter Sources',
                                    icon: 'pi pi-fw pi-list',
                                    routerLink: ['/ref-data/sources'],
                                },
                                {
                                    label: 'ARB Blotter',
                                    icon: 'pi pi-fw pi-list',
                                    routerLink: ['/ref-data/arb-blotter'],
                                },
                                {
                                    label: 'EOD Access',
                                    icon: 'pi pi-fw pi-list',
                                    routerLink: ['/ref-data/eod-access'],
                                },
                            ]
                        },
                        {
                            label: 'Calendars',
                            icon: 'pi pi-fw pi-calendar',
                            routerLink: ['/ref-data/calendars'],
                        },
                        {
                            label: 'Client',
                            icon: 'pi pi-fw pi-shield',
                            items: [
                                {
                                    label: 'Clients',
                                    icon: 'pi pi-fq pi-user-edit',
                                    routerLink: ['/ref-data/client'],
                                },
                                {
                                    label: 'Clients Blakclisting',
                                    icon: 'pi pi-fq pi-user-edit',
                                    routerLink: ['/ref-data/client-blacklisting'],
                                },
                                {
                                    label: 'Clients Restrictions',
                                    icon: 'pi pi-fq pi-user-edit',
                                    routerLink: ['/ref-data/client-restrictions'],
                                },
                                {
                                    label: 'Brokerage and Commission',
                                    icon: 'pi pi-fq pi-user-edit',
                                    routerLink: ['/ref-data/brokerage-commission'],
                                },
                                {
                                    label: 'Exec Evidence',
                                    icon: 'pi pi-fq pi-user-edit',
                                    routerLink: ['/ref-data/exev-evidence'],
                                },
                            ]
                        },
                        {
                            label: 'Entities',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/ref-data/entity'],
                        },
                        {
                            label: 'Location/Region',
                            icon: 'pi pi-fw pi-globe',
                            routerLink: ['/ref-data/location'],
                        },
                        {
                            label: 'Orders',
                            icon: 'pi pi-fw pi-globe',
                            routerLink: ['/ref-data/orders'],
                        },
                        {
                            label: 'Pricing',
                            icon: 'pi pi-fw pi-money-bill',
                            routerLink: ['/ref-data/pricing'],
                        },
                        {
                            label: 'Exch Curr & Units',
                            icon: 'pi pi-fw pi-money-bill',
                            items: [
                                {
                                    label: 'Exchange Currencies',
                                    icon: 'pi pi-fw pi-money-bill',
                                    routerLink: ['/ref-data/exchange-currencies'],
                                },
                                {
                                    label: 'Units',
                                    icon: 'pi pi-fw pi-money-bill',
                                    routerLink: ['/ref-data/units'],
                                },
                            ]
                        },
                        {
                            label: 'Product Setup',
                            icon: 'pi pi-fw pi-box',
                            routerLink: ['/ref-data/product-setup'],
                        },
                        {
                            label: 'RFQ',
                            icon: 'pi pi-fw pi-briefcase',
                            routerLink: ['/ref-data/rfq'],
                        },
                        {
                            label: 'Traders',
                            icon: 'pi pi-fw pi-user',
                            routerLink: ['/ref-data/trader'],
                        },
                        {
                            label: 'Trinity Integration',
                            icon: 'pi pi-fw pi-sitemap',
                            routerLink: ['/ref-data/integration'],
                        },  
                        {
                            label: 'Recaps',
                            icon: 'pi pi-fw pi-file-export',
                            routerLink: ['/ref-data/recaps'],
                        },  
                        {
                            label: 'UNA Allocations',
                            icon: 'pi pi-fw pi-file-export',
                            routerLink: ['/ref-data/una'],
                        },           
                    ],
                },
                // {
                //     label: 'Recent Orders/Trades',
                //     icon: 'pi pi-th-large',
                //     routerLink: ['/'],
                // },
                // {
                //     label: 'Trades',
                //     icon: 'pi pi-fw pi-file',
                //     items: [
                //         {
                //             label: 'View Trades',
                //             icon: 'pi pi-fw pi-list',
                //             routerLink: ['/trade/view'],
                //         },
                //         {
                //             label: 'New Trade',
                //             icon: 'pi pi-fw pi-check-square',
                //             routerLink: ['/trade/new'],
                //         },
                //         {
                //             label: 'Trade Integration',
                //             icon: 'pi pi-fw pi-bookmark',
                //             routerLink: ['/trade/integration'],
                //         },
                //         {
                //             label: 'Refresh Trades Cache',
                //             icon: 'pi pi-fw pi-exclamation-circle',
                //             routerLink: ['/trade/cache'],
                //         },
                //     ],
                // },
                 {
                     label: 'Orders',
                     icon: 'pi pi-fw pi-file-edit',
                     items: [
                        {
                            label: 'UNA Allocations',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/order/una'],
                        },
                     ],
                 },
                // {
                //     label: 'Tickets',
                //     icon: 'pi pi-fw pi-tag',
                //     items: [
                //         {
                //             label: 'Search Tickets',
                //             icon: 'pi pi-fw pi-list',
                //             routerLink: ['/blocks'],
                //         },
                //         {
                //             label: 'New Ticket',
                //             icon: 'pi pi-fw pi-check-square',
                //             routerLink: ['/ticket/new'],
                //         },
                //     ],
                // },
                // {
                //     label: 'Blotter',
                //     icon: 'pi pi-fw pi-inbox',
                //     items: [
                //         {
                //             label: 'GSAH',
                //             icon: 'pi pi-fw pi-list',
                //             routerLink: ['/blocks'],
                //         },
                //         {
                //             label: 'GSARB-CME',
                //             icon: 'pi pi-fw pi-check-square',
                //             url: ['https://www.primefaces.org/primeblocks-ng'],
                //             target: '_blank',
                //         },
                //         {
                //             label: 'GSCA',
                //             icon: 'pi pi-fw pi-list',
                //             routerLink: ['/blocks'],
                //         },
                //         {
                //             label: 'GSNI',
                //             icon: 'pi pi-fw pi-list',
                //             routerLink: ['/blocks'],
                //         },
                //         {
                //             label: 'GSPB',
                //             icon: 'pi pi-fw pi-list',
                //             routerLink: ['/blocks'],
                //         },
                //         {
                //             label: 'GSSN',
                //             icon: 'pi pi-fw pi-list',
                //             routerLink: ['/blocks'],
                //         },
                //         {
                //             label: 'GSPB',
                //             icon: 'pi pi-fw pi-list',
                //             routerLink: ['/blocks'],
                //         },
                //         {
                //             label: 'MMAA',
                //             icon: 'pi pi-fw pi-list',
                //             routerLink: ['/blocks'],
                //         },
                //         {
                //             label: 'MMAH',
                //             icon: 'pi pi-fw pi-list',
                //             routerLink: ['/blocks'],
                //         },
                //         {
                //             label: 'MMCA',
                //             icon: 'pi pi-fw pi-list',
                //             routerLink: ['/blocks'],
                //         },
                //         {
                //             label: 'MMNI',
                //             icon: 'pi pi-fw pi-list',
                //             routerLink: ['/blocks'],
                //         },
                //         {
                //             label: 'MMPB',
                //             icon: 'pi pi-fw pi-list',
                //             routerLink: ['/blocks'],
                //         },
                //         {
                //             label: 'MMSN',
                //             icon: 'pi pi-fw pi-list',
                //             routerLink: ['/blocks'],
                //         },
                //         {
                //             label: 'Copper USARB',
                //             icon: 'pi pi-fw pi-list',
                //             routerLink: ['/blocks'],
                //         },
                //         {
                //             label: 'Hand Off',
                //             icon: 'pi pi-fw pi-list',
                //             routerLink: ['/blocks'],
                //         },
                //     ],
                // },
                // {
                //     label: 'RFQ',
                //     icon: 'pi pi-fw pi-comment',
                //     routerLink: ['/rfq'],
                // },
                // {
                //     label: 'FIX/RFO',
                //     icon: 'pi pi-fw pi-comments',
                //     routerLink: ['/fix'],
                // },
            ];
        }
        else {
            this.model = [
                {
                    label: 'Accounts',
                    icon: 'pi pi-fw pi-users',
                    items: [
                        {
                            label: 'User Profiles',
                            icon: 'pi pi-fw pi-user',
                            routerLink: ['/accounts/user-profiles'],
                        }
                    ],
                },
                {
                    label: 'Ref Data',
                    icon: 'pi pi-home',
                    items: [
                        {
                            label: 'Security',
                            icon: 'pi pi-fw pi-shield',
                            items: [
                                {
                                    label: 'Users',
                                    icon: 'pi pi-fw pi-users',
                                    routerLink: ['/ref-data/security-users'],
                                },
                                {
                                    label: 'Roles',
                                    icon: 'pi pi-fw pi-shield',
                                    routerLink: ['/ref-data/security-roles'],
                                },
                            ]
                        },
                        {
                            label: 'Portfolio',
                            icon: 'pi pi-fw pi-sitemap',
                            routerLink: ['/ref-data/portfolio'],
                        },
                        {
                            label: 'Blotters',
                            icon: 'pi pi-fw pi-list',
                            items: [
                                {
                                    label: 'Blotter',
                                    icon: 'pi pi-fw pi-list',
                                    routerLink: ['/ref-data/blotter'],
                                },
                                {
                                    label: 'Blotter Sources',
                                    icon: 'pi pi-fw pi-list',
                                    routerLink: ['/ref-data/sources'],
                                },
                                {
                                    label: 'ARB Blotter',
                                    icon: 'pi pi-fw pi-list',
                                    routerLink: ['/ref-data/arb-blotter'],
                                },
                                {
                                    label: 'EOD Access',
                                    icon: 'pi pi-fw pi-list',
                                    routerLink: ['/ref-data/eod-access'],
                                },
                            ]
                        },
                        {
                            label: 'Calendars',
                            icon: 'pi pi-fw pi-calendar',
                            routerLink: ['/ref-data/calendars'],
                        },
                        {
                            label: 'Client',
                            icon: 'pi pi-fw pi-shield',
                            items: [
                                {
                                    label: 'Clients',
                                    icon: 'pi pi-fq pi-user-edit',
                                    routerLink: ['/ref-data/client'],
                                },
                                {
                                    label: 'Clients Blakclisting',
                                    icon: 'pi pi-fq pi-user-edit',
                                    routerLink: ['/ref-data/client-blacklisting'],
                                },
                                {
                                    label: 'Clients Restrictions',
                                    icon: 'pi pi-fq pi-user-edit',
                                    routerLink: ['/ref-data/client-restrictions'],
                                },
                                {
                                    label: 'Brokerage and Commission',
                                    icon: 'pi pi-fq pi-user-edit',
                                    routerLink: ['/ref-data/brokerage-commission'],
                                },
                                {
                                    label: 'Exec Evidence',
                                    icon: 'pi pi-fq pi-user-edit',
                                    routerLink: ['/ref-data/exev-evidence'],
                                },
                            ]
                        },
                        {
                            label: 'Entities',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/ref-data/entity'],
                        },
                        {
                            label: 'Location/Region',
                            icon: 'pi pi-fw pi-globe',
                            routerLink: ['/ref-data/location'],
                        },
                        {
                            label: 'Orders',
                            icon: 'pi pi-fw pi-globe',
                            routerLink: ['/ref-data/orders'],
                        },
                        {
                            label: 'Pricing',
                            icon: 'pi pi-fw pi-money-bill',
                            routerLink: ['/ref-data/pricing'],
                        },
                        {
                            label: 'Exch Curr & Units',
                            icon: 'pi pi-fw pi-money-bill',
                            items: [
                                {
                                    label: 'Exchange Currencies',
                                    icon: 'pi pi-fw pi-money-bill',
                                    routerLink: ['/ref-data/exchange-currencies'],
                                },
                                {
                                    label: 'Units',
                                    icon: 'pi pi-fw pi-money-bill',
                                    routerLink: ['/ref-data/units'],
                                },
                            ]
                        },
                        {
                            label: 'Product Setup',
                            icon: 'pi pi-fw pi-box',
                            routerLink: ['/ref-data/product-setup'],
                        },
                        {
                            label: 'RFQ',
                            icon: 'pi pi-fw pi-briefcase',
                            routerLink: ['/ref-data/rfq'],
                        },
                        {
                            label: 'Traders',
                            icon: 'pi pi-fw pi-user',
                            routerLink: ['/ref-data/trader'],
                        },
                        {
                            label: 'Trinity Integration',
                            icon: 'pi pi-fw pi-sitemap',
                            routerLink: ['/ref-data/integration'],
                        },  
                        {
                            label: 'Recaps',
                            icon: 'pi pi-fw pi-file-export',
                            routerLink: ['/ref-data/recaps'],
                        },   
                        {
                            label: 'UNA Allocations',
                            icon: 'pi pi-fw pi-file-export',
                            routerLink: ['/ref-data/una'],
                        },         
                    ],
                },
                {
                    label: 'Recent Orders/Trades',
                    icon: 'pi pi-th-large',
                    routerLink: ['/'],
                },
                {
                    label: 'Trades',
                    icon: 'pi pi-fw pi-file',
                    items: [
                        {
                            label: 'View Trades',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/trade/view'],
                        },
                        {
                            label: 'New Trade',
                            icon: 'pi pi-fw pi-check-square',
                            routerLink: ['/trade/new'],
                        },
                        {
                            label: 'Trade Integration',
                            icon: 'pi pi-fw pi-bookmark',
                            routerLink: ['/trade/integration'],
                        },
                        {
                            label: 'Refresh Trades Cache',
                            icon: 'pi pi-fw pi-exclamation-circle',
                            routerLink: ['/trade/cache'],
                        },
                    ],
                },
                {
                    label: 'Orders',
                    icon: 'pi pi-fw pi-file-edit',
                    items: [
                        {
                            label: 'View Orders',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/order/view'],
                        },
                        {
                            label: 'New Order',
                            icon: 'pi pi-fw pi-check-square',
                            url: ['https://www.primefaces.org/primeblocks-ng'],
                            target: '_blank',
                        },
                        {
                            label: 'Manage Orders',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/order/manage'],
                        },
                        {
                            label: 'UNA Allocations',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/order/una'],
                        },
                    ],
                },
                {
                    label: 'Tickets',
                    icon: 'pi pi-fw pi-tag',
                    items: [
                        {
                            label: 'Search Tickets',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/blocks'],
                        },
                        {
                            label: 'New Ticket',
                            icon: 'pi pi-fw pi-check-square',
                            routerLink: ['/ticket/new'],
                        },
                    ],
                },
                {
                    label: 'Blotter',
                    icon: 'pi pi-fw pi-inbox',
                    items: [
                        {
                            label: 'GSAH',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/blocks'],
                        },
                        {
                            label: 'GSARB-CME',
                            icon: 'pi pi-fw pi-check-square',
                            url: ['https://www.primefaces.org/primeblocks-ng'],
                            target: '_blank',
                        },
                        {
                            label: 'GSCA',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/blocks'],
                        },
                        {
                            label: 'GSNI',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/blocks'],
                        },
                        {
                            label: 'GSPB',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/blocks'],
                        },
                        {
                            label: 'GSSN',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/blocks'],
                        },
                        {
                            label: 'GSPB',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/blocks'],
                        },
                        {
                            label: 'MMAA',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/blocks'],
                        },
                        {
                            label: 'MMAH',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/blocks'],
                        },
                        {
                            label: 'MMCA',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/blocks'],
                        },
                        {
                            label: 'MMNI',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/blocks'],
                        },
                        {
                            label: 'MMPB',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/blocks'],
                        },
                        {
                            label: 'MMSN',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/blocks'],
                        },
                        {
                            label: 'Copper USARB',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/blocks'],
                        },
                        {
                            label: 'Hand Off',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['/blocks'],
                        },
                    ],
                },
                {
                    label: 'RFQ',
                    icon: 'pi pi-fw pi-comment',
                    routerLink: ['/rfq'],
                },
                {
                    label: 'FIX/RFO',
                    icon: 'pi pi-fw pi-comments',
                    routerLink: ['/fix'],
                },
            ];
        }
    }
}
